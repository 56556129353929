import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const UploadedImages = () => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      fetchImages();
    } else {
      setError('Please log in to view your images');
    }
  }, [isAuthenticated]);

  const fetchImages = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(`${API_URL}/api/images`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setImages(response.data);
      setError(null);
    } catch (error) {
      // console.error('Error fetching images:', error.response ? error.response.data : error.message);
      setError('Failed to fetch images. Please try again later.');
    }
  };

  const handleMarkAsDownloaded = async (imageId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/api/download/${imageId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setImages(images.filter(image => image._id !== imageId));
    } catch (error) {
      // console.error('Error marking image as downloaded:', error);
      setError('Failed to mark image as downloaded. Please try again.');
    }
  };

  const handleDownload = async (imageId, originalName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/download/${imageId}`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      });

      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = originalName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      // console.error('Download error:', error);
      setError('Failed to download image. Please try again.');
    }
  };

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-4">Your Uploaded Images</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {images.length === 0 ? (
              <p>No images found. Upload some images from the dashboard!</p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {images.map((image) => (
                  <div key={image._id} className="border rounded p-2">
                    <img
                      src={`${API_URL}${image.compressedUrl}`}
                      alt={image.originalName}
                      className="w-full h-40 object-cover mb-2"
                      onError={(e) => {
                        // console.error('Compressed image failed to load:', e.target.src);
                      }}
                    />
                    <p className="text-sm">Name: {image.originalName}</p>
                    <p className="text-sm">Original Size: {(image.originalSize / 1024).toFixed(2)} KB</p>
                    <p className="text-sm">Compressed Size: {(image.compressedSize / 1024).toFixed(2)} KB</p>
                    <p className="text-sm">Compression: {image.compressionRatio}%</p>
                    <button
                      onClick={() => handleMarkAsDownloaded(image._id)}
                      className="text-blue-500 hover:underline mr-2"
                    >
                      Mark as Downloaded
                    </button>
                    <button
                      onClick={() => handleDownload(image._id, image.originalName)}
                      className="text-blue-500 hover:underline"
                    >
                      Download Compressed
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadedImages;