import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const Dashboard = () => {
  const { user, isSubscriptionActive } = useAuth();
  const [images, setImages] = useState([]);
  const [selectedCompressionLevel, setSelectedCompressionLevel] = useState('balanced');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [usageStats, setUsageStats] = useState(null);
  const [recentlyCompressed, setRecentlyCompressed] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);

  useEffect(() => {
    fetchImages();
    fetchUsageStats();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/images`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setImages(response.data);
    } catch (error) {
      // console.error('Error fetching images:', error);
    }
  };

  const fetchUsageStats = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/usage-stats`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      // console.log('Received usage stats:', response.data); // Add this line
      setUsageStats(response.data);
    } catch (error) {
      // console.error('Error fetching usage stats:', error);
    }
  };

  const handleFileUpload = useCallback(async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      // console.log('Adding file to upload:', file.name);
      formData.append('images', file);
    });
    formData.append('compressionLevel', selectedCompressionLevel);

    // console.log('Selected compression level:', selectedCompressionLevel);

    setIsCompressing(true);
    try {
      // console.log('Sending request to server');
      const response = await axios.post(`${API_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
          // console.log('Upload progress:', percentCompleted + '%');
        }
      });

      // console.log('Upload successful:', response.data);
      setUploadProgress(0);
      setRecentlyCompressed(response.data);
      fetchImages();
      fetchUsageStats();
    } catch (error) {
      // console.error('Upload error:', error.response ? error.response.data : error.message);
      // console.error('Full error object:', error);
      setUploadProgress(0);
    } finally {
      setIsCompressing(false);
    }
  }, [selectedCompressionLevel]);

  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles);
  }, [handleFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const formatStorageUsage = (usedMB, totalGB) => {
    if (usedMB >= 1024) {
      return `${(usedMB / 1024).toFixed(2)} GB / ${totalGB} GB`;
    }
    return `${usedMB.toFixed(2)} MB / ${totalGB} GB`;
  };

  const handleMarkAsDownloaded = async (imageId) => {
    try {
      await axios.post(`${API_URL}/api/download/${imageId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setImages(images.filter(image => image._id !== imageId));
      fetchUsageStats();
    } catch (error) {
      // console.error('Error marking image as downloaded:', error);
    }
  };

  const handleDownload = async (image) => {
    // console.log('Downloading image:', image); // Debug log
    if (!image.compressedUrl) {
      // console.error('Compressed URL is undefined');
      // Optionally, show an error message to the user
      return;
    }
    try {
      const response = await axios.get(`${API_URL}${image.compressedUrl}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob',
      });

      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = image.originalName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      // console.error('Download error:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold">Welcome to Your Dashboard</h1>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <p>Your compression statistics:</p>
                <ul className="list-disc space-y-2">
                  <li className="flex items-start">
                    <span className="h-6 flex items-center sm:h-7">
                      <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </span>
                    <p className="ml-2">
                      Total storage used: <code className="text-sm font-bold text-gray-900">
                        {usageStats ? formatStorageUsage(usageStats.storageUsed, usageStats.maxStorageGB) : 'Loading...'}
                      </code>
                    </p>
                  </li>
                </ul>
                <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                  <p>Want to compress a new image?</p>
                  <div className="mt-4">
                    <select
                      className="mb-2 p-2 border rounded w-full"
                      value={selectedCompressionLevel}
                      onChange={(e) => setSelectedCompressionLevel(e.target.value)}
                    >
                      {[
                        { value: 'lossless', label: 'Lossless (Best Quality)' },
                        { value: 'balanced', label: 'Balanced (Good Quality, Smaller Size)' },
                        { value: 'aggressive', label: 'Aggressive (Smallest Size)' }
                      ].map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    <div {...getRootProps()} className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <input {...getInputProps()} />
                      <div className="space-y-1 text-center">
                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <span>Browse files</span>
                          </label>
                          <p className="pl-1">or drop files here</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 50MB</p>
                      </div>
                    </div>
                    {uploadProgress > 0 && (
                      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
                        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                      </div>
                    )}
                    {isCompressing && <p>Compressing images...</p>}
                  </div>
                </div>
              </div>
            </div>
            {recentlyCompressed.length > 0 && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Recently Compressed Images</h2>
                <div className="grid grid-cols-2 gap-4">
                  {recentlyCompressed.map((image, index) => (
                    <div key={index} className="border rounded p-2">
                      <img src={`${API_URL}${image.compressedUrl}`}
                        alt="Compressed" className="w-full h-32 object-cover mb-2" />
                      <p className="text-sm">Original: {(image.originalSize / 1024).toFixed(2)} KB</p>
                      <p className="text-sm">Compressed: {(image.compressedSize / 1024).toFixed(2)} KB</p>
                      <p className="text-sm">Compression: {image.compressionRatio.toFixed(2)}%</p>
                      <button
                        onClick={() => handleDownload(image)}
                        className="mt-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
                      >
                        Download
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-8">
              <Link to="/uploaded-images" className="text-blue-500 hover:underline">
                View All Uploaded Images
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;