import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import the useAuth hook

const Home = () => {
  const { isAuthenticated } = useAuth(); // Use the useAuth hook
  const navigate = useNavigate(); // Use the useNavigate hook for programmatic navigation

  const handleButtonClick = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/signup');
    }
  };

  return (
    <div className="flex flex-col w-full">
      {/* Hero Section */}
      <section className="w-full h-screen bg-gradient-to-r from-blue-600 to-indigo-600 text-white flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">
              Professional Image Compression for Every Industry
            </h1>
            <p className="text-xl mb-8 opacity-80">
              Optimize your images without compromising quality. Perfect for professionals who need to maintain image integrity while reducing file sizes.
            </p>
            <button
              onClick={handleButtonClick}
              className="bg-white text-blue-600 hover:bg-blue-100 font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-block"
            >
              {isAuthenticated ? 'Go to Dashboard' : 'Start Compressing'}
            </button>
          </div>
          <div className="md:w-1/2 md:ml-12">
            <div className="bg-white rounded-lg shadow-xl p-4">
              <img src="/hero_img.png" alt="ProCompress in action" className="rounded w-full" />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose ProCompress?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon="🎨"
              title="Smart Compression"
              description="Advanced algorithms ensure optimal compression while preserving image quality."
            />
            <FeatureCard
              icon="⚡"
              title="Lightning Fast"
              description="Compress hundreds of images in minutes with our efficient batch processing."
            />
            <FeatureCard
              icon="🎯"
              title="Industry-Specific Presets"
              description="Tailored compression settings for various industries and use cases."
            />
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <Step number="1" title="Upload" description="Select and upload your images" />
            <Step number="2" title="Compress" description="Choose your compression settings" />
            <Step number="3" title="Download" description="Get your optimized images" />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full bg-blue-600 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to optimize your images?</h2>
          <p className="mb-8 text-xl opacity-80">Join thousands of professionals using ProCompress today.</p>
          <button
            onClick={handleButtonClick}
            className="bg-white text-blue-600 hover:bg-blue-100 font-bold py-3 px-8 rounded-full text-lg transition duration-300 inline-block"
          >
            {isAuthenticated ? 'Go to Dashboard' : 'Subscribe To A Plan'}
          </button>
        </div>
      </section>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const Step = ({ number, title, description }) => {
  return (
    <div className="text-center mb-8 md:mb-0">
      <div className="bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold mb-4 mx-auto">
        {number}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default Home;