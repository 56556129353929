import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const Pricing = () => {
  const { isAuthenticated, user, isSubscriptionActive, checkAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [priceNGN, setPriceNGN] = useState(null);
  const priceUSD = 2.99;

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    // console.log('Pricing component:', { isAuthenticated, user, isSubscriptionActive });
  }, [isAuthenticated, user, isSubscriptionActive]);

  const handleSubscribe = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_URL}/api/subscribe`, { email: user.email });
      setPriceNGN(response.data.priceNGN);
      window.location.href = response.data.authorization_url;
    } catch (error) {
      // console.error('Subscription error:', error);
      setError('Failed to initiate subscription. Please try again.');
      setIsLoading(false);
    }
  };

  const renderActionButton = () => {
    if (!isAuthenticated) {
      return (
        <Link
          to="/signup"
          className="block text-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
        >
          Sign Up
        </Link>
      );
    } else if (!isSubscriptionActive) {
      return (
        <button
          onClick={handleSubscribe}
          disabled={isLoading}
          className="w-full text-center bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300 disabled:bg-green-400"
        >
          {isLoading ? 'Processing...' : 'Subscribe Now'}
        </button>
      );
    } else {
      return (
        <div className="space-y-2">
          <div className="text-center text-green-600 font-semibold">
            ✓ Subscribed
          </div>
          <Link
            to="/dashboard"
            className="block text-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          >
            Go to Dashboard
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold text-center mb-12">Simple Pricing for Everyone</h1>
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-center">Basic Plan</h2>
        <p className="text-4xl font-bold mb-6 text-center">
          ${priceUSD}<span className="text-sm font-normal">/month</span>
        </p>
        {priceNGN && (
          <p className="text-lg mb-6 text-center">
            Approximately ₦{priceNGN.toLocaleString()} NGN
          </p>
        )}
        <ul className="mb-6">
          {[
            'Up to 100 images/month',
            '5GB storage',
            'Basic compression algorithm',
            'Web optimization',
            'Email support'
          ].map((feature, index) => (
            <li key={index} className="mb-2 flex items-center">
              <svg className="h-5 w-5 mr-2 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M5 13l4 4L19 7"></path>
              </svg>
              {feature}
            </li>
          ))}
        </ul>
        <p className="mb-4 text-center">Perfect for individuals and small businesses</p>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        {renderActionButton()}
      </div>
    </div>
  );
};

export default Pricing;