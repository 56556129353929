import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const SubscriptionCallback = () => {
  const [status, setStatus] = useState('Verifying payment...');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, checkAuth } = useAuth();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyPayment = async () => {
      if (verificationAttempted.current) return;
      verificationAttempted.current = true;

      const searchParams = new URLSearchParams(location.search);
      const reference = searchParams.get('reference');

      if (reference) {
        try {
          // console.log('Verifying payment with reference:', reference);
          const response = await axios.get(`${API_URL}/api/verify-subscription?reference=${reference}`);
          // console.log('Verification response:', response.data);

          if (response.data.success) {
            setStatus('Payment successful! Logging in...');
            await login({ user: response.data.user, token: response.data.token });
            await checkAuth();
            setStatus('Redirecting to dashboard...');
            setTimeout(() => navigate('/dashboard'), 2000);
          } else {
            // console.error('Payment verification failed:', response.data);
            setStatus(`Payment verification failed: ${response.data.message}`);
            setTimeout(() => navigate('/pricing'), 2000);
          }
        } catch (error) {
          // console.error('Subscription verification error:', error.response?.data || error);
          setStatus(`An error occurred: ${error.response?.data?.message || error.message}`);
          setTimeout(() => navigate('/pricing'), 2000);
        }
      } else {
        setStatus('Invalid callback. Redirecting...');
        setTimeout(() => navigate('/pricing'), 2000);
      }
    };

    verifyPayment();
  }, [location, navigate, login, checkAuth]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{status}</h2>
      </div>
    </div>
  );
};

export default SubscriptionCallback;