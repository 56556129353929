import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = useCallback(async (navigate) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/api/check-subscription`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data.user);
        setIsSubscriptionActive(response.data.isActive);
        setIsAuthenticated(true);
        if (navigate) {
          if (response.data.isActive) {
            navigate('/dashboard');
          } else {
            navigate('/pricing');
          }
        }
      } catch (error) {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUser(null);
        setIsSubscriptionActive(false);
        if (navigate) navigate('/login');
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
      setIsSubscriptionActive(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = async (userData, navigate) => {
    setIsAuthenticated(true);
    setUser(userData.user);
    setIsSubscriptionActive(userData.user.isSubscribed);
    localStorage.setItem('token', userData.token);
    await checkAuth(navigate);
  };

  const logout = (navigate) => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    setIsSubscriptionActive(false);
    if (navigate) navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, isSubscriptionActive, login, logout, checkAuth }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);