import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="sticky top-0 z-50 bg-white shadow-sm">
      <nav className="max-w-6xl mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-blue-600">ProCompress</Link>

          {/* Hamburger menu button */}
          <button onClick={toggleMenu} className="md:hidden">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          {/* Desktop menu */}
          <ul className="hidden md:flex space-x-6 items-center">
            <NavItems isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
          </ul>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <ul className="md:hidden mt-4 space-y-2">
            <NavItems isAuthenticated={isAuthenticated} handleLogout={handleLogout} isMobile={true} />
          </ul>
        )}
      </nav>
    </header>
  );
};

const NavItems = ({ isAuthenticated, handleLogout, isMobile }) => (
  <>
    <li><Link to="/pricing" className="hover:text-blue-600 block py-2">Pricing</Link></li>
    {isAuthenticated ? (
      <>
        <li><Link to="/dashboard" className="hover:text-blue-600 block py-2">Dashboard</Link></li>
        <li><Link to="/uploaded-images" className="hover:text-blue-600 block py-2">Uploaded Images</Link></li>
        <li><Link to="/profile" className="hover:text-blue-600 block py-2">Profile</Link></li>
        <li>
          <button onClick={handleLogout} className={`bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-300 ${isMobile ? 'w-full text-left' : ''}`}>
            Logout
          </button>
        </li>
      </>
    ) : (
      <>
        <li><Link to="/login" className="hover:text-blue-600 block py-2">Login</Link></li>
        <li>
          <Link to="/signup" className={`bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-300 block ${isMobile ? 'text-center' : ''}`}>
            Sign Up
          </Link>
        </li>
      </>
    )}
  </>
);

export default Header;